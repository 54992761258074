import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";
import { PROPS_NEWANSWER, PROPS_UPDATEDANSWER } from "../types";

const apiUrl = process.env.REACT_APP_DEV_API_URL;
const apiUrlPostDetail = `${process.env.REACT_APP_DEV_API_URL}api/post-detail/`;
const apiUrlProfileUser = `${process.env.REACT_APP_DEV_API_URL}api/profile-user/`;
const apiUrlQuestionSearch = `${process.env.REACT_APP_DEV_API_URL}api/question-search/`;
const apiUrlAnswerSearch = `${process.env.REACT_APP_DEV_API_URL}api/answer-search/`;
const apiUrlProfile = `${process.env.REACT_APP_DEV_API_URL}api/profiles-for-posts/`;
const apiUrlAnswer = `${process.env.REACT_APP_DEV_API_URL}api/answer/`;
const apiUrlPatchLiked = `${process.env.REACT_APP_DEV_API_URL}api/patch-liked/`;

export const fetchAsyncGetMyProf = createAsyncThunk(
    "postDetail/profile/get",
    async () => {
        const res = await axios.get(`${apiUrl}api/myprofile/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);


export const fetchAsyncGetPostDetail = createAsyncThunk(
    "postDetail/post-detail/get",
    async (postId: string) => {
        const res = await axios.get(`${apiUrlPostDetail}${postId}/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncGetProfileUser = createAsyncThunk(
    "postDetail/profile-user/get",
    async (userId: string) => {
        const res = await axios.get(`${apiUrlProfileUser}${userId}/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncSearchQuestions = createAsyncThunk(
    "postDetail/question-search",
    async (postIds: string[]) => {
        const data = {
            postIds: postIds
        };
        const res = await axios.post(`${apiUrlQuestionSearch}`, data, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncSearchAnswers = createAsyncThunk(
    "postDetail/answer-search",
    async(questionIds: string[]) => {
        const data = {
            questionIds: questionIds
        };
        const res = await axios.post(`${apiUrlAnswerSearch}`, data, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncGetProfsForPosts = createAsyncThunk(
    "postDetail/profiles-for-posts",
    async (postIds: string[]) => {
        const data = {
            postIds: postIds
        };
        const res = await axios.post(`${apiUrlProfile}`, data, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncNewAnswer = createAsyncThunk(
    "postDetail/answer/post",
    async (newAnswer: PROPS_NEWANSWER) => {
        const uploadData = new FormData();
        uploadData.append("answerContent", newAnswer.answerContent.toString());
        uploadData.append("sourceQuestion", newAnswer.sourceQuestion);
        uploadData.append("is_public", newAnswer.is_public.toString());

        const res = await axios.post(apiUrlAnswer, uploadData, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncUpdateAnswer = createAsyncThunk(
    "postDetail/answer/update",
    async (updatedAnswer: PROPS_UPDATEDANSWER) => {
        const uploadData = new FormData();
        uploadData.append("answerContent", updatedAnswer.answerContent.toString());

        const res = await axios.patch(`${apiUrlAnswer}${updatedAnswer.id}/`, uploadData, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncPatchLiked = createAsyncThunk(
    "postDetail/patchLiked",
    async (postId: string) => {
        const res = await axios.patch(`${apiUrlPatchLiked}${postId}/`, {}, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const postDetailSlice = createSlice({
    name:"postDetail",
    initialState: {
        isLoading: false,
        myprofile: {
            id: "",
            nickName: "",
            userProfile: "",
            created_on: "",
            img: "",
            text: "",
            twitterId: "",
            point: "",
            point_limit: "",
        },
        post: {
            id: "",
            userPost: "",
            text: "",
            img: "",
            liked: [""],
            created_on: "",
            is_public: true,
        },
        questionsOnPost: [
            {
                id: "",
                sourcePost: "",
                userQuestion: "",
                text: "",
                creator_answer: "",
                created_on: "",
                is_public: true,
            },
        ],
        answers: [
            {
                id: "",
                sourceQuestion: "",
                userAnswer: "",
                answerContent: "",
                created_on: "",
                is_public: true,
            },
        ],
        profileOnPost: {
            id: "",
            nickName: "",
            userProfile: "",
            created_on: "",
            img: "",
        },
        profiles: [
            {
                id: "",
                nickName: "",
                userProfile: "",
                created_on: "",
                img: "",
            },
        ],

    },
    reducers: {
        setIsLoading(state) {
            state.isLoading = true;
        },
        resetIsLoading(state) {
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncGetMyProf.fulfilled, (state, action) => {
            state.myprofile = action.payload;
        });
        builder.addCase(fetchAsyncGetPostDetail.fulfilled, (state, action) => {
            state.post = action.payload;
        });
        builder.addCase(fetchAsyncSearchQuestions.fulfilled, (state, action) => {
            state.questionsOnPost = action.payload;
        });
        builder.addCase(fetchAsyncSearchAnswers.fulfilled, (state, action) => {
            state.answers = action.payload;
        });
        builder.addCase(fetchAsyncGetProfileUser.fulfilled, (state, action) => {
            state.profileOnPost = action.payload;
        });
        builder.addCase(fetchAsyncGetProfsForPosts.fulfilled, (state, action) => {
            state.profiles = action.payload;
        });
        builder.addCase(fetchAsyncNewAnswer.fulfilled, (state, action) => {
            return {
                ...state,
                answers: [...state.answers, action.payload],
            };
        });
        builder.addCase(fetchAsyncUpdateAnswer.fulfilled, (state, action) => {
            const index = state.answers.findIndex(answer => answer.id === action.payload.id);
            state.answers[index] = action.payload;
        });
        builder.addCase(fetchAsyncPatchLiked.fulfilled, (state, action) => {
            state.post = action.payload;
        });
    },
});

export const {
    setIsLoading,
    resetIsLoading,
} = postDetailSlice.actions;

export const selectProfile = (state: RootState) => state.postDetail.myprofile;
export const selectIsLoading = (state: RootState) => state.postDetail.isLoading;
export const selectPost = (state: RootState) => state.postDetail.post;
export const selectQuestionsOnPost = (state: RootState) => state.postDetail.questionsOnPost;
export const selectAnswers = (state: RootState) => state.postDetail.answers;
export const selectProfiles = (state: RootState) => state.postDetail.profiles;
export const selectProfileOnPost = (state: RootState) => state.postDetail.profileOnPost;

export default postDetailSlice.reducer;