import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";
import { PROPS_AUTHEN, PROPS_PASSWORD_CHANGE, PROPS_PROFILE } from "../types";

const apiUrl = process.env.REACT_APP_DEV_API_URL;

export const fetchAsyncLogin = createAsyncThunk(
    "auth/post",
    async (authen: PROPS_AUTHEN) => {
        const res = await axios.post(`${apiUrl}api/auth/jwt/create/`, authen, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return res.data;
    }
);

// export const fetchAsyncSendRegisterLink = createAsyncThunk(
//     "auth/register",
//     async (auth: PROPS_AUTHEN) => {
//         const res = await axios.post(`${apiUrl}api/auth/users/`, auth, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//         return res.data;
//     }
// );
export const fetchAsyncSendRegisterLink = createAsyncThunk(
    "auth/register",
    async (auth: PROPS_AUTHEN, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${apiUrl}api/auth/users/`, auth, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            // 成功レスポンスからステータスコードとデータを返す
            return { status: res.status, data: res.data };
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                // エラーレスポンスからステータスコードとデータを返す
                return rejectWithValue({ status: error.response.status, data: error.response.data });
            } else {
                // その他のエラー
                return rejectWithValue({ status: 'Unknown', data: 'An unexpected error occurred' });
            }
        }
    }
);

export const fetchAsyncPasswordChange = createAsyncThunk(
    "auth/password-change",
    async(packet: PROPS_PASSWORD_CHANGE) => {
        const res = await axios.post(`${apiUrl}api/auth/users/set_password/`, packet, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        })
        return res.data;
    }
);

export const fetchAsyncPasswordReset = createAsyncThunk(
    "auth/password-reset",
    async(email: string) => {
        const res = await axios.post(`${apiUrl}api/auth/users/reset_password/`, {email: email}, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return res.data;
    }
)

export const fetchAsyncCreateProf = createAsyncThunk(
    "auth/profile/post",
    async (nickName: string) => {
        const res = await axios.post(`${apiUrl}api/profile/`, {nickName: nickName}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncGetMyProf = createAsyncThunk(
    "auth/profile/get",
    async () => {
        const res = await axios.get(`${apiUrl}api/myprofile/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncUpdateProf = createAsyncThunk(
    "auth/profile/put",
    async (profile: PROPS_PROFILE) => {
        const uploadData = new FormData();
        uploadData.append("nickName", profile.nickName);
        profile.img && uploadData.append("img", profile.img, profile.img.name);
        profile.text && uploadData.append("text", profile.text);
        profile.twitterId && uploadData.append("twitterId", profile.twitterId);
        const res = await axios.put(
            `${apiUrl}api/profile/${profile.id}/`,
            uploadData,
            {
                headers: {
                    Authorization: `JWT ${localStorage.localJWT}`,
                },
            }
        );
        return res.data;
    }
);

export const fetchAsyncUpdatePoint = createAsyncThunk(
    "auth/updatePoint",
    async(amount: number) => {
        const res = await axios.patch(
            `${apiUrl}api/update_point/`,
            {amount: amount},
            {
                headers: {
                    Authorization: `JWT ${localStorage.localJWT}`,
                },
            }
        );
        return res.data;
    }
);


export const authSlice = createSlice({
  name:"auth",
  initialState: {
    openSignIn: false,
    openSignUp: false,
    openProfile: false,
    isLoadingAuth: false,
    myprofile: {
        id: "",
        nickName: "",
        userProfile: "",
        created_on: "",
        img: "",
        text: "",
        twitterId: "",
        point: "",
        point_limit: "",
    },
  },
  reducers: {
    fetchCredStart(state) {
        state.isLoadingAuth = true;
    },
    fetchCredEnd(state) {
        state.isLoadingAuth = false;
    },
    setOpenSignIn(state) {
        state.openSignIn = true;
    },
    resetOpenSignIn(state) {
        state.openSignIn = false;
    },
    setOpenSignUp(state) {
        state.openSignUp = true;
    },
    resetOpenSignUp(state) {
        state.openSignUp = false;
    },
    setOpenProfile(state) {
        state.openProfile = true;
    },
    resetOpenProfile(state) {
        state.openProfile = false;
    },
    editNickname(state, action) {
        state.myprofile.nickName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncLogin.fulfilled, (state, action) => {
        localStorage.setItem("localJWT", action.payload.access);
    });
    builder.addCase(fetchAsyncCreateProf.fulfilled, (state, action) => {
        state.myprofile = action.payload;
    });
    builder.addCase(fetchAsyncGetMyProf.fulfilled, (state, action) => {
        state.myprofile = action.payload;
    });
    builder.addCase(fetchAsyncUpdateProf.fulfilled, (state, action) => {
        state.myprofile = action.payload;
    });
    builder.addCase(fetchAsyncUpdatePoint.fulfilled, (state, action) => {
        state.myprofile = action.payload;
    });
  },
});

export const {
    fetchCredStart,
    fetchCredEnd,
    setOpenSignIn,
    resetOpenSignIn,
    setOpenSignUp,
    resetOpenSignUp,
    setOpenProfile,
    resetOpenProfile,
    editNickname,
    
} = authSlice.actions;

export const selectIsLoadingAuth = (state: RootState) => state.auth.isLoadingAuth;
export const selectOpenSignIn = (state: RootState) => state.auth.openSignIn;
export const selectOpenSignUp = (state: RootState) => state.auth.openSignUp;
export const selectOpenProfile = (state: RootState) => state.auth.openProfile;
export const selectProfile = (state: RootState) => state.auth.myprofile;

export default authSlice.reducer;
