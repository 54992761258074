import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";

const apiUrl = process.env.REACT_APP_DEV_API_URL;

export const fetchAsyncGetEmail = createAsyncThunk(
    "editProfile/getEmail",
    async () => {
        const res = await axios.get(`${apiUrl}api/user-email/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
)

export const editProfileSlice = createSlice({
    name:"editProfile",
    initialState: {
        isOpenEditProfile: false,
        userEmail: "",
    },
    reducers: {
        setOpenEditProfile(state) {
            state.isOpenEditProfile = true;
        },
        resetOpenEditProfile(state) {
            state.isOpenEditProfile = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncGetEmail.fulfilled, (state, action) => {
            state.userEmail = action.payload.email;
        });
    },
});

export const {
    setOpenEditProfile,
    resetOpenEditProfile,
} = editProfileSlice.actions;

export const selectIsOpenEditProfile = (state: RootState) => state.editProfile.isOpenEditProfile;
export const selectUserEmail = (state: RootState) => state.editProfile.userEmail;

export default editProfileSlice.reducer;