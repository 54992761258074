import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";



export const newpostSlice = createSlice({
    name:"newpost",
    initialState: {
        isLoadingGenerateQuestion: false,
        openNewPost: false,
    },
    reducers: {
        setIsLoadingGenerateQuestion(state) {
            state.isLoadingGenerateQuestion = true;
        },
        resetIsLoadingGenerateQuestion(state) {
            state.isLoadingGenerateQuestion = false;
        },
        setOpenNewPost(state) {
            state.openNewPost = true;
        },
        resetOpenNewPost(state) {
            state.openNewPost = false;
        },
    }
});

export const {
    setIsLoadingGenerateQuestion,
    resetIsLoadingGenerateQuestion,
    setOpenNewPost,
    resetOpenNewPost,
} = newpostSlice.actions;

export const selectIsLoadingGenerateQuestion = (state: RootState) => state.newpost.isLoadingGenerateQuestion;
export const selectOpenNewPost = (state: RootState) => state.newpost.openNewPost;

export default newpostSlice.reducer;