import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import heic2any from "heic2any";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";

import {

    fetchPostStart,
    fetchPostEnd,
} from "../post/postSlice";

import {
    fetchAsyncNewPost,
    fetchAsyncNewQuestion,
    fetchAsyncNewAnswer,
} from "../core/coreSlice";

import {
    selectProfile,
    fetchAsyncUpdatePoint,
} from "../auth/authSlice";

import {
    setIsLoadingGenerateQuestion,
    resetIsLoadingGenerateQuestion,
    selectIsLoadingGenerateQuestion,
    selectOpenNewPost,
    resetOpenNewPost,
} from "./newpostSlice";

// import { File } from "../types";
import { PROPS_NEWQUESTION, PROPS_QA } from "../types";

import styles from "./NewPost.module.css";
import { Button, TextField, IconButton, CircularProgress, Rating, Slider, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@material-ui/icons/Add";
import { MdAddAPhoto } from "react-icons/md";
import { FormControlLabel, Checkbox } from "@mui/material";

const apiUrlGenerate = `${process.env.REACT_APP_DEV_API_URL}api/process-text/`;

const customStyles = {
  content: {
    top: "55%",
    left: "50%",

    width: "65%",
    height: "auto",
    padding: "50px",

    transform: "translate(-50%, -50%)",
  },
};

const NewPost: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const openNewPost = useSelector(selectOpenNewPost);
    const isLoadingGenerateQuestion = useSelector(selectIsLoadingGenerateQuestion);

    const [image, setImage] = useState<File | null>(null);
    const [inputA, setInputA] = useState("");
    const [inputB, setInputB] = useState<PROPS_QA[]>([]);
    const [isPublic, setIsPublic] = useState(true);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);


    const myprofile = useSelector(selectProfile);

    // const convertToJPG = (file: File) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //         const img = document.createElement("img");
    //         img.src = reader.result as string;
    //         img.onload = () => {
    //             const canvas = document.createElement("canvas");
    //             const ctx = canvas.getContext("2d");
    //             canvas.width = img.width;
    //             canvas.height = img.height;
    //             ctx?.drawImage(img, 0, 0);
    //             canvas.toBlob((blob) => {
    //                 if (blob) {
    //                     const convertedFile = new File([blob], "converted.jpg", { type: "image/jpeg" });
    //                     setImage(convertedFile);
    //                 }
    //             }, "image/jpeg");
    //         };
    //     };
    // };


    const convertToJPG = async (file: File) => {
        const reader = new FileReader();
        const extension = file.name.split('.').pop()?.toLowerCase();
    
        const readImage = async (imageFile: Blob) => {            

            if (extension === 'heic' || extension === 'heif') {
                try {
                    const output = await heic2any({
                        blob: imageFile,
                        toType: "image/jpeg",
                    });
                    const outputBlob = output as Blob;
                    reader.readAsDataURL(outputBlob);
                } catch (error) {
                    console.error("Error converting image:", error);
                }
            } else {
                reader.readAsDataURL(imageFile);
            }   
        };

        reader.onload = () => {
            const img = document.createElement("img");
            img.src = reader.result as string;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;
                ctx?.drawImage(img, 0, 0);
                canvas.toBlob((blob) => {
                    if (blob) {
                        const convertedFile = new File([blob], "converted.jpg", { type: "image/jpeg" });
                        setImage(convertedFile);
                    }
                }, "image/jpeg");
            };
        };
        await readImage(file);
    };
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            convertToJPG(file);
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        }
    };

    const handleEditPicture = () => {
        const fileInput = document.getElementById("imageInput");
        fileInput?.click();
    };

    const handleGenerate = async() => {
        const packet = new FormData();
        packet.append("input_text", inputA);
        if (image) {
            packet.append("image", image);
        }
        dispatch(setIsLoadingGenerateQuestion());
        try {
            const response = await axios.post(apiUrlGenerate, packet, {
                headers: {
                    Authorization: `JWT ${localStorage.localJWT}`
                }
            });
            setInputB(response.data.questions);
        } catch(error) {
            if (axios.isAxiosError(error)) {
                console.error('Error:', error.response?.data);
            } else {
                console.error('OpenAI API Error:', error);
            }
            dispatch(resetIsLoadingGenerateQuestion());
        } finally {
            dispatch(resetIsLoadingGenerateQuestion());
        }
    };

    const handleInputChange = (index: number, value: string) => {
        const newInputB = [...inputB];
        newInputB[index] = {...newInputB[index], text: value};
        setInputB(newInputB);
    };

    const handleSliderChange = (index: number, value: number) => {
        const newInputB = [...inputB];
        newInputB[index] = {...newInputB[index], answer: value};
        setInputB(newInputB);
    };

    const handleDelete = (index: number) => {
        const newInputB = inputB.filter((_, i) => i!== index);
        setInputB(newInputB);
    }

    const handleAddNewPair = () => {
        const newPair = {sourcePost: '', text: '', answer: 3, is_public: true};//sourcePostは送信時に追加
        setInputB([...inputB, newPair]);
    }

    const newPost = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const packet = {text: inputA, img: image, is_public: isPublic};
        await dispatch(fetchPostStart());

        const postResponse = await dispatch(fetchAsyncNewPost(packet));
        const sourcePostId = postResponse.payload.id;

        if (inputB.length > 0) {
            await Promise.all(inputB.map(async (qa) => {
                const packetQuestion = {
                    text: qa.text,
                    sourcePost: sourcePostId,
                    creator_answer: qa.answer,
                    is_public: isPublic,
                };
                const questionResponse = await dispatch(fetchAsyncNewQuestion(packetQuestion));
                const sourceQuestionId = questionResponse.payload.id;

                const answerPacket = {
                    answerContent: qa.answer,
                    sourceQuestion: sourceQuestionId,
                    is_public: isPublic,
                };
                await dispatch(fetchAsyncNewAnswer(answerPacket));
            }));
        }
        await dispatch(fetchPostEnd());
        setInputA("");
        setInputB([]);
        setImage(null);
        dispatch(resetOpenNewPost());
    }

    return (
        <>
            <Modal
                isOpen={openNewPost}
                onRequestClose={async () =>{
                    await dispatch(resetOpenNewPost());
                }}
                style={customStyles}
            >
                <form className={styles.newpost}>
                    <h1 className={styles.core_title}>New Post</h1>
                    {isLoadingGenerateQuestion && <CircularProgress />}
                    <br />

                    <TextField
                        placeholder="your tweet"
                        type="text"
                        multiline
                        rows={4}
                        variant="outlined"
                        className={styles.textfield_large}
                        onChange={(e) => setInputA(e.target.value)}
                        helperText={`Generate ${inputA.length}/4000`}
                        FormHelperTextProps={{ style: { textAlign: "right" } }}
                    />
                    <input
                        type="file"
                        id="imageInput"
                        hidden={true}
                        onChange={handleFileChange}
                    />
                    <IconButton onClick={handleEditPicture}>
                        <MdAddAPhoto />
                    </IconButton>

                    {previewUrl && (
                        <>
                        <img src={previewUrl} alt="Preview" className={ styles.previewImage} />
                        <br/>
                        </>
                    )}
                    
                    {inputB.map((qa, index) => (
                    <Grid container spacing={2} alignItems="center" key={index}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="text"
                                value={qa.text}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <Slider
                                value={Number(qa.answer)}
                                min={1}
                                max={5}
                                valueLabelDisplay="auto"
                                onChange={(e, value) => {
                                    typeof value === 'number' && handleSliderChange(index, value);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => handleDelete(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    ))}

                    <IconButton onClick={handleAddNewPair}>
                        <AddIcon />
                    </IconButton>
                    <br />

                    <Button
                        disabled={!inputA || Number(myprofile.point) > Number(myprofile.point_limit) || inputA.length > 4000}
                        variant="contained"
                        color="primary"
                        onClick={handleGenerate}
                    >
                        Generate
                    </Button>
                    <br/>
                    

                    <Button
                        disabled={!inputA}
                        variant="contained"
                        color="primary"
                        onClick={newPost}
                    >
                        Post
                    </Button>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isPublic}
                                onChange={(e) => setIsPublic(e.target.checked)}
                                name="isPublic"
                            />
                        }
                        label="Public"
                    />
                </form>
            
                <br/>
                Today {Number(myprofile.point).toFixed(1)}/{Number(myprofile.point_limit).toFixed(1)}
                
                {(Number(myprofile.point) > Number(myprofile.point_limit)) && (
                    <><br/>Due to exceeding the point limit, generating is temporarily unavailable. Points will be reset at 4:00a.m.</>
                )}

            </Modal>
        </>
    )
}

export default NewPost;
