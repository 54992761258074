import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from "../features/auth/authSlice";
import postReducer from "../features/post/postSlice";
import newpostReducer from "../features/newpost/newpostSlice";
import matchReducer from "../features/match/matchSlice";
import coreReducer from "../features/core/coreSlice";
import profileReducer from "../features/profile/profileSlice";
import profProfileReducer from "../features/profile/profProfile/profProfileSlice";
import postProfileReducer from "../features/profile/postProfile/postProfileSlice";
import answerProfileReducer from "../features/profile/answerProfile/answerProfileSlice";
import postDetailReducer from "../features/postDetail/postDetailSlice";
import editProfileReducer from "../features/editProfile/editProfileSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    post: postReducer,
    newpost: newpostReducer,
    match: matchReducer,
    core: coreReducer,
    profile: profileReducer,
    profProfile: profProfileReducer,
    postProfile: postProfileReducer,
    answerProfile: answerProfileReducer,
    postDetail: postDetailReducer,
    editProfile: editProfileReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;