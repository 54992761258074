import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

import axios from "axios";

const apiUrlMatch = `${process.env.REACT_APP_DEV_API_URL}api/match/`;
const apiUrlProfFromIds = `${process.env.REACT_APP_DEV_API_URL}api/profile-userids/`

export const fetchAsyncMatch = createAsyncThunk(
    "match",
    async () => {
        setIsLoadingMatch();
        const res = await axios.get(apiUrlMatch, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncGetProfFromIds = createAsyncThunk(
    "match/profs-from-ids",
    async (userIds: string[]) => {
        const res = await axios.post(apiUrlProfFromIds, {userIds: userIds}, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const matchSlice = createSlice({
    name:"match",
    initialState: {
        isLoadingMatch: false,
        isOpenMatch: false,
        matchResult: [
            {
                userId: "",
                score: "",
            },
        ],
        profiles: [
            {
                id: "",
                nickName: "",
                userProfile: "",
                created_on: "",
                img: "",
            },
        ],
    },
    reducers: {
        setIsLoadingMatch(state) {
            state.isLoadingMatch = true;
        },
        resetIsLoadingMatch(state) {
            state.isLoadingMatch = false;
        },
        setIsOpenMatch(state) {
            state.isOpenMatch = true;
        },
        resetIsOpenMatch(state) {
            state.isOpenMatch = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncMatch.fulfilled, (state, action) => {
            const matchResultsArray = Object.entries(action.payload).map(([userId, score]) => ({
                userId,
                // scoreが数値であることを確認し、そうでない場合はデフォルト値を使用
                score: typeof score === 'number' ? score.toString() : '0',
            }));
            state.matchResult = matchResultsArray;
        });
        builder.addCase(fetchAsyncGetProfFromIds.fulfilled, (state, action) => {
            state.profiles = action.payload;
        });
    }
});

export const {
    setIsLoadingMatch,
    resetIsLoadingMatch,
    setIsOpenMatch,
    resetIsOpenMatch,
} = matchSlice.actions;

export const selectIsLoadingMatch = (state: RootState) => state.match.isLoadingMatch;
export const selectIsOpenMatch = (state: RootState) => state.match.isOpenMatch;
export const selectMatchResult = (state: RootState) => state.match.matchResult;
export const selectProfiles = (state: RootState) => state.match.profiles;

export default matchSlice.reducer;