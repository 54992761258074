import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";

const apiUrl = process.env.REACT_APP_DEV_API_URL;

export const fetchAsyncGetMyProf = createAsyncThunk(
    "profile/profile/get",
    async () => {
        const res = await axios.get(`${apiUrl}api/myprofile/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const profileSlice = createSlice({
    name:"profile",
    initialState: {
        openingTab: "post",
        loginProfile: {
            id: "",
            nickName: "",
            userProfile: "",
            created_on: "",
            img: "",
            text: "",
            twitterId: "",
            point: "",
            point_limit: "",
        },
    },
    reducers: {
        setTabPost(state) {
            state.openingTab = "post";
        },
        setTabAnswer(state) {
            state.openingTab = "answer";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncGetMyProf.fulfilled, (state, action) => {
            state.loginProfile = action.payload;
        });
    }
});

export const {
    setTabPost,
    setTabAnswer,
} = profileSlice.actions;

export const selectOpeningTab = (state: RootState) => state.profile.openingTab;
export const selectLoginProfile = (state: RootState) => state.profile.loginProfile;


export default profileSlice.reducer;
