import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';

import { PROPS_PROFILE } from "../types";
import { Button, TextField, IconButton, Grid } from "@mui/material";
import styles from "./EditProfile.module.css";
import { MdAddAPhoto } from "react-icons/md";

import {
  fetchAsyncCreateProf,
  fetchAsyncUpdateProf,
  fetchCredStart,
  fetchCredEnd,
  fetchAsyncPasswordChange,
  fetchAsyncPasswordReset,
  selectProfile,
} from "../auth/authSlice";

import {
  selectIsOpenEditProfile,
  setOpenEditProfile,
  resetOpenEditProfile,
  fetchAsyncGetEmail,
  selectUserEmail,
} from "./editProfileSlice";

import {
  setOpenTimeLine,
} from "../core/coreSlice";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "65%",
    height: "auto", // 高さを自動に設定
    maxHeight: "90%", // ビューポートの高さに対する最大高さを設定
    padding: "50px",
    overflow: "auto", // コンテンツがオーバーフローした場合にスクロールバーを表示
  },
};

const EditProf = () => {
  const dispatch: AppDispatch = useDispatch();
  const profile = useSelector(selectProfile);
  // const fullProfile = useSelector(selectFullProfile);
  const [tempNickName, setTempNickName] = useState(profile?.nickName);
  const [tempText, setTempText] = useState(profile?.text);
  const [tempTwitterId, setTempTwitterId] = useState(profile?.twitterId);
  const [tempImage, setTempImage] = useState<File | null>(null);
  const openEditProfile = useSelector(selectIsOpenEditProfile);

  const [tempCurrentPassword, setTempCurrentPassword] = useState("");
  const [tempNewPassword, setTempNewPassword] = useState("");
  const [tempReNewPassword, setTempReNewPassword] = useState("");
  const userEmail = useSelector(selectUserEmail);

  const updateProfile = async(e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (tempNickName) {
        if (profile.id) {
        e.preventDefault();
        const packet = {id: profile.id, nickName: tempNickName, img: tempImage, twitterId: tempTwitterId, text: tempText};

        await dispatch(fetchCredStart());
        await dispatch(fetchAsyncUpdateProf(packet));
        await dispatch(fetchCredEnd());
        await dispatch(resetOpenEditProfile());
      } else {
        const res = await dispatch(fetchAsyncCreateProf(tempNickName));
        if (fetchAsyncCreateProf.fulfilled.match(res)) {
          console.log("Profile created successfully");
          dispatch(setOpenTimeLine());
          dispatch(resetOpenEditProfile());
        } else {
          console.error("Failed to create profile");
        }
      }
    } else {
      alert("Username is requruired.");
    }
  }

  const handleEditPicture = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput?.click();
  }

  const handleChangePassword = async(e: React.MouseEvent<HTMLElement>) => {
    const packet = {current_password: tempCurrentPassword, new_password: tempNewPassword, re_new_password: tempReNewPassword};
    const res = await dispatch(fetchAsyncPasswordChange(packet));
    alert(`Password has been successfully changed.`);
  };

  const handleResetPassword = async() => {
    dispatch(fetchCredStart());
    const res = await dispatch(fetchAsyncPasswordReset(userEmail));
    dispatch(fetchCredEnd());
    alert(`Password reset link has been sent to your email.`);
  }

  useEffect(() => {
    if (profile) {
      setTempNickName(profile.nickName ?? '');
      setTempText(profile.text ?? '');
      setTempTwitterId(profile.twitterId ?? '');
    }
  }, [profile]);

  useEffect(() => {
    const fetchEmail = async () => {
      dispatch(fetchAsyncGetEmail());
    }
    fetchEmail();
  }, []);

  return (
    <>
      <Modal
        isOpen={openEditProfile}
        onRequestClose={async () => {
          await dispatch(resetOpenEditProfile());
        }}
        style={customStyles}
      >
        <h1 className={styles.core_title}>Edit Profile</h1>
        <br />

        <Grid container spacing={2}>
        <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          Username:
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            placeholder='Username'
            type="text"
            value={tempNickName}
            onChange={(e) => setTempNickName(e.target.value)}
          />
        </Grid>

        <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <input
            type="file"
            id="imageInput"
            hidden={true}
            onChange={(e) => setTempImage(e.target.files![0])}
          />
          <br/>
          <IconButton onClick={handleEditPicture}>
            <MdAddAPhoto />
          </IconButton>
        </Grid>

        <Grid item xs={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', paddingTop: '30px'}}>
          Profile:
        </Grid>
        <Grid item xs={11}>
          <TextField
            fullWidth
            placeholder="profile"
            type="text"
            value={tempText}
            onChange={(e) => setTempText(e.target.value)}
            multiline
            rows={5}
          />
        </Grid>

        <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          X id:
        </Grid>
        <Grid item xs={11}>
          <TextField
            fullWidth
            placeholder="X ID"
            type="text"
            value={tempTwitterId}
            onChange={(e) => setTempTwitterId(e.target.value)}
          />
        </Grid>
      </Grid>

      <br />

      <Button
        disabled={!tempNickName}
        variant="contained"
        color="primary"
        type="submit"
        onClick={updateProfile}
      >
        Send
      </Button>
      <br/><br/>

{/*       <h3>Password settings</h3>
      Current Password:
      <TextField
        type="password"
        value={tempCurrentPassword}
        onChange={(e) => setTempCurrentPassword(e.target.value)}
      />
      <br/>
      New Password:
      <TextField
        type="password"
        value={tempNewPassword}
        onChange={(e) => setTempNewPassword(e.target.value)}
      />
      <br/>
      Retype New Password:
      <TextField
        type="password"
        value={tempReNewPassword}
        onChange={(e) => setTempReNewPassword(e.target.value)}
      />
      <br/>
      <Button
        disabled={!tempNewPassword || !tempReNewPassword}
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleChangePassword}
      >
        Send
      </Button> */}

{/*       <br/>
      <Button
        onClick={handleResetPassword}
      >
        Send a password reset email
      </Button> */}

      

      </Modal>
    </>
  )
};

export default EditProf
