import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { useLocation } from 'react-router-dom';

import Auth from "../auth/Auth";
import Post from "../post/Post";
import NewPost from "../newpost/NewPost";
import Match from "../match/Match";
import EditProf from '../editProfile/EditProfile';

import styles from "./Core.module.css";

import { TfiPencilAlt } from "react-icons/tfi";
import { BiSolidNetworkChart } from "react-icons/bi";
import { FaUserFriends } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { BsQuestion } from "react-icons/bs";

import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Avatar,
  Badge,
  CircularProgress,
  IconButton,
} from "@material-ui/core";

import {
  selectProfile,
  selectIsLoadingAuth,
  fetchAsyncGetMyProf,
  setOpenSignIn,
  resetOpenSignIn,
  resetOpenSignUp,
  setOpenSignUp,
  // editNickname,
  resetOpenProfile,
  setOpenProfile,
} from "../auth/authSlice";

import {
  selectIsLoadingPost,
} from "../post/postSlice";

import {
  setOpenNewPost,
  resetOpenNewPost,
} from "../newpost/newpostSlice";

import {
  selectPosts,
  selectQuestions,
  selectAnswers,
  fetchAsyncGetPosts,
  fetchAsyncSearchQuestions,
  fetchAsyncSearchAnswers,
  fetchAsyncGetProfsForPosts,
} from "./coreSlice";

import {
  resetIsOpenMatch,
  setIsOpenMatch,
} from "../match/matchSlice"

import {
  selectOpenTimeLine,
  setOpenTimeLine,
} from "../core/coreSlice";
import { resetOpenEditProfile, setOpenEditProfile } from '../editProfile/editProfileSlice';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

export const Core = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const profile = useSelector(selectProfile);
  const posts = useSelector(selectPosts);
  const questions = useSelector(selectQuestions);
  const isLoadingPost = useSelector(selectIsLoadingPost);
  const isLoadingAuth = useSelector(selectIsLoadingAuth);
  const openTimeLine = useSelector(selectOpenTimeLine);

  const apiUrl = process.env.REACT_APP_DEV_API_URL;

  useEffect(() => {
    const fetchBootLoader = async() => {
      if (localStorage.localJWT) {
        dispatch(resetOpenSignIn());
        const result = await dispatch(fetchAsyncGetMyProf());

        if (fetchAsyncGetMyProf.rejected.match(result)) {
          const errorResponse = result?.error?.message;
          if (errorResponse === "Request failed with status code 401") {
            console.log("Handling 401 Unauthorized Error.");
            localStorage.removeItem("localJWT");
            dispatch(setOpenSignIn());
          } else {
            console.log("Handling other errors.(user exists but no profile)");
            dispatch(setOpenEditProfile());
          }
          return null;
        };
        await dispatch(fetchAsyncGetPosts());
    } else {
      dispatch(setOpenSignIn());
    };
  }
    fetchBootLoader();
  }, [dispatch, openTimeLine]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modal = searchParams.get('modal');
    
    if (modal === 'signup') {
      dispatch(setOpenSignUp());
      dispatch(resetOpenSignIn());
    }
  }, [location.search, dispatch]);

  useEffect(() => {
    const postIds = posts.map(post => post.id);
    const fetchQuestions = async() => {
      await dispatch(fetchAsyncSearchQuestions(postIds));
    };
    fetchQuestions();

    const fetchProfs = async() => {
      await dispatch(fetchAsyncGetProfsForPosts(postIds));
    }
    fetchProfs();
  }, [posts, profile]);

  useEffect(() => {
    const questionIds = questions.map(question => question.id);
    const fetchAnswers = async() => {
      await dispatch(fetchAsyncSearchAnswers(questionIds));
    };
    fetchAnswers();
  }, [questions]);

  return (
    <div>
        <Auth />
        <NewPost />
        <Match />
        <EditProf />
        
        <div className={styles.core_header}>
          <h1 className={styles.core_title}>GravitonAI</h1>

          <IconButton
                className={styles.core_btnModal}
                onClick={() => {
                  window.open('https://nonchalant-rise-caf.notion.site/Graviton-b6815e92b0d5452993966ac3b66e1639', '_blank');
                }}
              >
                <BsQuestion />
          </IconButton>
          
          {localStorage.localJWT ? (
            <>
              <IconButton
                className={styles.core_btnModal}
                onClick={() => {
                  dispatch(setOpenNewPost());
                }}
              >
                <GoPlus />
              </IconButton>

              <IconButton
                onClick={() => {
                  dispatch(setIsOpenMatch());
                }}
              >
                <FaUserFriends />
              </IconButton>

              
              <div className={styles.core_logout}>
                {(isLoadingPost || isLoadingAuth) && <CircularProgress />}
                <Button
                  onClick={() => {
                    localStorage.removeItem("localJWT");
                    // dispatch(editNickname(""));
                    // dispatch(resetOpenProfile());
                    dispatch(resetOpenNewPost());
                    dispatch(setOpenSignIn());
                    window.location.reload();
                  }}
                >
                  Logout
                </Button>
                
              <button
                className={styles.core_btnModal}
                onClick={() => {
                  dispatch(resetOpenNewPost());
                  dispatch(resetIsOpenMatch());
                  dispatch(setOpenEditProfile());
                }}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                >
                  <Avatar alt={profile?.nickName} src={`${profile.img}`} />{" "}
                </StyledBadge>
              </button>
              </div>
            </>
          ) : (
            <div>
              <Button
                onClick={() => {
                  dispatch(setOpenSignIn());
                  dispatch(resetOpenSignUp());
                }}
              >
                Login
              </Button>

              <Button
                onClick={() => {
                  dispatch(setOpenSignUp());
                  dispatch(resetOpenSignIn());
                }}
              >
                SignUp
              </Button>
            </div>
          )}
        </div>

            <>
              {posts.length > 0 && (
              <div className={styles.core_posts}>
                <Grid container spacing={4}>
                  {posts
                    .slice(0)
                    .reverse()
                    .map((post) => (
                      <Grid key={post.id} item xs={12} md={4}>
                        <Post
                          postId={post.id}
                         text={post.text}
                          loginId={profile.userProfile}
                          userPost={post.userPost}
                          imageUrl={post.img}
                          liked={post.liked}
                          is_public={true}
                        />
                      </Grid>
                    ))}
                </Grid>
              </div>
              )}
            </>

    </div>
  )
}

export default Core;
