import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import { Link } from 'react-router-dom';

import { Grid, Checkbox, Divider, Rating, Avatar, AvatarGroup, Button, IconButton } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import DeleteIcon from '@mui/icons-material/Delete';
import styles from "./AnswerProfile.module.css";
import styles_link from "../../post/Post.module.css";

import {
  selectAnswers,
  fetchAsyncGetAnswerUser,
  selectQuestions,
  fetchAsyncGetQuestionFromAnswer,
  fetchAsyncDeleteAnswer,
} from "./answerProfileSlice";

import {
  fetchAsyncGetMyProf,
  selectLoginProfile,
} from "../profileSlice"

interface PROPS_INPUT {
  userId?: string,
}

const AnswerProfile: React.FC<PROPS_INPUT> = ({userId}) => {
  const dispatch: AppDispatch = useDispatch();
  const answers = useSelector(selectAnswers);
  const questions = useSelector(selectQuestions);
  const profile = useSelector(selectLoginProfile);
  const loginId = profile.userProfile;

  const [open, setOpen] = useState(false);
  const [selectedAnswerId, setSelectedAnswerId] = useState<string  | null>(null);

  const handleClickOpen = (answerId: string) => {
    setOpen(true);
    setSelectedAnswerId(answerId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (selectedAnswerId) {
      dispatch(fetchAsyncDeleteAnswer(selectedAnswerId));
      setOpen(false);
    }
  };

  useEffect(() => {
    const fetchAnswers = async() => {
      if (userId) {
        dispatch(fetchAsyncGetAnswerUser(userId));
      };
      dispatch(fetchAsyncGetMyProf());
    };
    fetchAnswers();
  }, []);

  useEffect(() => {
    const fetchQuestions = async() => {
      const answerIds = answers.map(answer => answer.id);
      dispatch(fetchAsyncGetQuestionFromAnswer(answerIds));
    };
    fetchQuestions();
  }, [answers]);

  return (
    // <div>Answers
    //   {answers.map((answer) => {
    //   const question = questions.find(question => question.id === answer.sourceQuestion);
    //   return (
    //     <p key={answer.id}>
    //       {question ? `${question.text}` : ''} {answer.answerContent}
    //     </p>
    //   );
    // })}
    // </div>

    <div>
    {answers.slice(0).map((answer) => {
      const question = questions.find((question) => question.id === answer.sourceQuestion);
      
      return (
        <Grid key={answer.id} item xs={12} md={4}>
          <div className={styles.post}>
            {question?.text}
            <br/>
            <Rating
              name="read-only"
              value={Number(answer.answerContent)}
              readOnly
              sx={{
                color: 'red', // 薄いグレー色で表示
              }}
            />
            <br/>
            {/* <Link to={`/post-detail/${question?.sourcePost}/`} className={styles_link.customLink}>Post</Link> */}
            <a
              href={`/post-detail/${question?.sourcePost}/`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles_link.customLink}
            >
              Post
            </a>

            {loginId == answer.userAnswer && (
            <IconButton color="primary" onClick={() => handleClickOpen(answer.id)}>
              <DeleteIcon/>
            </IconButton>
            )}
          </div>
        </Grid>
      );
    })}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this answer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary" style={{ color: 'red' }} autoFocus>
            Delete
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

        </DialogActions>
      </Dialog>
  </div>
  )
}

export default AnswerProfile
