import React, { useEffect } from 'react'
import { AppDispatch } from '../../app/store'
import { useSelector, useDispatch } from 'react-redux'
import styles from "./Auth.module.css";
import Modal from "react-modal";
import { Link } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, Button, CircularProgress } from "@material-ui/core";

import {
    selectIsLoadingAuth,
    selectOpenSignIn,
    selectOpenSignUp,
    setOpenSignIn,
    resetOpenSignIn,
    setOpenSignUp,
    resetOpenSignUp,
    fetchCredStart,
    fetchCredEnd,
    fetchAsyncLogin,
    fetchAsyncSendRegisterLink,
    fetchAsyncGetMyProf,
} from "./authSlice";

import {
    setOpenTimeLine,
} from "../core/coreSlice";

const customStyles = {
    overlay: {
        backgroundColor: "#777777",
      },
      content: {
        top: "55%",
        left: "50%",
    
        width: 280,
        height: 350,
        padding: "50px",
    
        transform: "translate(-50%, -50%)",
      },
};

const Auth: React.FC = () => {
  Modal.setAppElement("#root");
  const openSignIn = useSelector(selectOpenSignIn);
  const openSignUp = useSelector(selectOpenSignUp);
  const isLoadingAuth = useSelector(selectIsLoadingAuth);
  const dispatch: AppDispatch = useDispatch();

  return (
    <>
        <Modal
            isOpen={openSignUp}
            onRequestClose={async () => {
                await dispatch(resetOpenSignUp());
            }}
            style={customStyles}
        >
            <Formik
                initialErrors={{ email: "required" }}
                initialValues={{ email: "", password: ""}}
                onSubmit={async (values) => {
                    await dispatch(fetchCredStart());
                    const valuesWithRePassword = {
                        ...values,
                        re_password: values.password,
                    };
                    
                    const actionResult = await dispatch(fetchAsyncSendRegisterLink(valuesWithRePassword));
                    await dispatch(fetchCredEnd());
                    if (fetchAsyncSendRegisterLink.fulfilled.match(actionResult)) {
                        // アクションが成功した場合の処理
                        if (actionResult.payload.status === 201) {
                            alert("Authentication link has been sent to your email address.");
                            await dispatch(resetOpenSignUp());
                            await dispatch(setOpenSignIn());
                        }
                    } else if (fetchAsyncSendRegisterLink.rejected.match(actionResult)) {
                        // アクションがリジェクトされた場合の処理
                        const payload = actionResult.payload as { status?: number; data?: any; }; // 型アサーションを追加
                        if (payload && payload.status === 400) {
                            const errorMessages = Object.values(payload.data).flat().join('\n'); // 配列の要素をスペースで結合
                            alert(errorMessages);
                        }
                    }
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .required("email is required"),
                    password: Yup.string()
                        .required("password is required").min(4),
                })}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    isValid,
                }) => (
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.auth_signUp}>
                                <h1 className={styles.auth_title}>GravitonAI</h1>
                                <br />
                                <div className={styles.auth_progress}>
                                    {isLoadingAuth && <CircularProgress />}
                                </div>
                                <br />

                                <TextField
                                    placeholder="email"
                                    type="input"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                                <br />
                                {touched.email && errors.email ? (
                                    <div className={styles.auth_error}>{errors.email}</div>
                                ) : null}
                                
                                <TextField
                                    placeholder="password"
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                                {touched.password && errors.password ? (
                                    <div className={styles.auth_error}>{errors.password}</div>
                                ) : null}
                                <br />
                                <br />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!isValid}
                                    type="submit"
                                >
                                    Register
                                </Button>
                                <br />
                                <br />

                                <span
                                    className={styles.auth_text}
                                    onClick={async () => {
                                        await dispatch(setOpenSignIn());
                                        await dispatch(resetOpenSignUp());
                                }}
                                >
                                    You already have an account?
                                </span>
                            </div>
                        </form>
                    </div>
                )}
            </Formik>
        </Modal>

        <Modal
            isOpen={openSignIn}
            onRequestClose={async () => {
                await dispatch(resetOpenSignIn());
            }}
            style={customStyles}
        >
            <Formik
                initialErrors={{ email: "required" }}
                initialValues={{ email: "", password: ""}}
                onSubmit={async (values) => {
                    await dispatch(fetchCredStart());
                    const result = await dispatch(fetchAsyncLogin(values));
                    if (fetchAsyncLogin.fulfilled.match(result)) {
                        dispatch(setOpenTimeLine());
                        await dispatch(fetchAsyncGetMyProf());
                    }
                    await dispatch(fetchCredEnd());
                    await dispatch(resetOpenSignIn());
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .required("email is required"),
                    password: Yup.string()
                        .required("password is required").min(4),
                })}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    isValid,
                }) => (
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.auth_signUp}>
                                <h1 className={styles.auth_title}>GravitonAI</h1>
                                <br />
                                <div className={styles.auth_progress}>
                                    {isLoadingAuth && <CircularProgress />}
                                </div>
                                <br />

                                <TextField
                                    placeholder="email"
                                    type="input"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                                {touched.password && errors.password ? (
                                    <div className={styles.auth_error}>{errors.password}</div>
                                ) : null}
                                <br />
                                
                                <TextField 
                                    placeholder="password"
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                                {touched.password && errors.password ? (
                                    <div className={styles.auth_error}>{errors.password}</div>
                                ) : null}
                                <br />
                                <br />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!isValid}
                                    type="submit"
                                >
                                    Login
                                </Button>
                                <br />
                                <Link to="/password-reset-form" className={styles.auth_link}>
                                    Forgot your password?
                                </Link>
                                <br />

                                <span
                                    className={styles.auth_text}
                                    onClick={async () => {
                                        await dispatch(resetOpenSignIn());
                                        await dispatch(setOpenSignUp());
                                    }}
                                >
                                    You don't have an account?
                                </span>
                            </div>
                        </form>
                    </div>
                )}
            </Formik>

        </Modal>
    </>
  )
}

export default Auth
