import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';

import { Grid, Checkbox, Divider, Rating, Avatar, Button, IconButton } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import styles from "./PostProfile.module.css";

import {
  fetchAsyncGetMyProf,
  selectLoginProfile,
} from "../profileSlice";

import {
  selectPosts,
  selectQuestions,
  selectAnswers,
  selectProfiles,
  fetchAsyncGetPostsUser,
  fetchAsyncSearchQuestions,
  fetchAsyncSearchAnswers,
  fetchAsyncGetProfsForPosts,
  fetchAsyncNewAnswer,
  fetchAsyncUpdateAnswer,
  fetchAsyncPatchLiked,
  fetchAsyncDeletePost,
} from "./postProfileSlice";

import {
  selectUserProfile,
  fetchAsyncGetProfileUser,
} from "../profProfile/profProfileSlice";

import { PROPS_QA_MAPPING, PROPS_NEWANSWER, PROPS_UPDATEDANSWER } from '../../types';

interface PROPS_INPUT {
  userId?: string,
}

interface LikedHandlerArgs {
  postId: string;
  text: string;
  current: string[];
}

const PostProfile: React.FC<PROPS_INPUT> = ({userId}) => {
  const dispatch: AppDispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  const profiles = useSelector(selectProfiles);
  const posts = useSelector(selectPosts);
  const questions = useSelector(selectQuestions);
  const answers = useSelector(selectAnswers);
  const loginProfile = useSelector(selectLoginProfile);
  const loginId = loginProfile.userProfile;
  const [mapping, setMapping] = useState<PROPS_QA_MAPPING>({});

  const [open, setOpen] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState<string  | null>(null);

  const apiUrl = process.env.REACT_APP_DEV_API_URL;

  const handleAnswerChange = (questionId: string, newValue: number | null) => {
    const existingAnswer = mapping[questionId];
    let value;
    if (newValue === null) {
        value = 0;
    } else {
        value = newValue;
    }

    if (existingAnswer) {
        const updatedAnswer: PROPS_UPDATEDANSWER = {
            id: existingAnswer.id,
            answerContent: value,
        };
        dispatch(fetchAsyncUpdateAnswer(updatedAnswer));
    } else {
        const newAnswer: PROPS_NEWANSWER = {
            sourceQuestion: questionId,
            answerContent: value,
            is_public: true,
        };
        dispatch(fetchAsyncNewAnswer(newAnswer));
    }
};

const handlerLiked = async(postId: string) => {
  await dispatch(fetchAsyncPatchLiked(postId));
};

  useEffect(() => {
    const fetchMyProf = async() => {
      dispatch(fetchAsyncGetMyProf());
    };
    fetchMyProf();
  }, []);

  useEffect(() => {
    const fetchProfiles = async() => {
      if (userId) {
        dispatch(fetchAsyncGetProfileUser(userId));
      };
    };
    fetchProfiles();
  }, []);

  useEffect(() => {
    const fetchPosts = async() => {

      if (userId) {
        dispatch(fetchAsyncGetPostsUser(userId));
      };
    };
    fetchPosts();
  }, []);

  useEffect(() => {
    const postIds = posts.map(post => post.id);
    const fetchQuestions = async() => {
      dispatch(fetchAsyncSearchQuestions(postIds));
    };
    fetchQuestions();

    const fetchProfiles = async() => {
      dispatch(fetchAsyncGetProfsForPosts(postIds));
    };
    fetchProfiles();
  }, [posts]);

  useEffect(() => {
    const questionIds = questions.map(question => question.id);
    const fetchAnswers = async() => {
      dispatch(fetchAsyncSearchAnswers(questionIds));
    };
    fetchAnswers();
  }, [questions]);

  useEffect(() => {
    const updatedMapping: PROPS_QA_MAPPING = {};
    questions.forEach((question) => {
        const correspondingAnswer = answers.find(answer => answer.sourceQuestion === question.id);
        if (correspondingAnswer) {
            updatedMapping[question.id] = correspondingAnswer;
        }
    });
    setMapping(updatedMapping);
}, [answers]);

const handleClickOpen = (postId: string) => {
  setOpen(true);
  setSelectedPostId(postId);
};

const handleClose = () => {
  setOpen(false);
};

const handleDelete = () => {
  if (selectedPostId) {
    dispatch(fetchAsyncDeletePost(selectedPostId));
    setOpen(false);
  }
};

  return (
    <div>
      {posts
        .slice(0)
        .map((post) => (
          <Grid key={post.id} item xs={12} md={4}>
            <div className={styles.post}>
              <div className={styles.post_text}>{post.text}</div>

              {post.img && <img className={styles.post_image} src={`${post.img}`} alt="" />}
              
              <h4 className={styles.post_text}>
                <Checkbox
                  className={styles.post_checkbox}
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  checked={post.liked.some((like) => like === loginId)}
                  onChange={() => handlerLiked(post.id)}
                  style={{color:"red",}}
                />
                <br/>
                <AvatarGroup max={7}>
                  {post.liked.map((like) => (
                    <Avatar
                      className={styles.post_avatarGroup}
                      key={like}
                      src={`${profiles.find((prof) => prof.userProfile === like)?.img}`}
                    />
                  ))}
                </AvatarGroup>
              </h4>

              {questions.some(question => question.sourcePost === post.id) && (
              <>
              <Divider />
              <div className={styles.post_comments}>
              {questions.filter(question => question.sourcePost === post.id)
                .map((question) => (
                  <div key={question.id}>
                    {question.text}{/* {question.creator_answer} */}
                    <Rating
                      value={mapping[question.id] ? (
                        Number(mapping[question.id].answerContent)
                      ) : (
                        0
                      )}
                      onChange={(event, newValue) => handleAnswerChange(question.id, newValue)}
                    />
                  </div>
                ))
              }
              </div>
              </>
              )}

            {loginId == post.userPost && (
            <IconButton color="primary" onClick={() => handleClickOpen(post.id)}>
              <DeleteIcon/>
            </IconButton>
            )}
              
            </div>
          </Grid>
        ))
      }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this answer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary" style={{ color: 'red' }} autoFocus>
            Delete
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PostProfile
