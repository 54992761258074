import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { AppDispatch } from "../../app/store";
import { PROPS_NEWANSWER, PROPS_UPDATEDANSWER } from "../types";

import styles from "./PostDetail.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { Rating, Avatar, Divider, Checkbox } from "@mui/material";

import {
    selectIsLoading,
    selectPost,
    selectQuestionsOnPost,
    selectAnswers,
    selectProfiles,
    selectProfileOnPost,
    setIsLoading,
    resetIsLoading,
    fetchAsyncGetPostDetail,
    fetchAsyncSearchQuestions,
    fetchAsyncSearchAnswers,
    fetchAsyncGetProfileUser,
    fetchAsyncGetProfsForPosts,
    fetchAsyncPatchLiked,
    fetchAsyncNewAnswer,
    fetchAsyncUpdateAnswer,
} from "./postDetailSlice";

import {

} from "../core/coreSlice";

import {
    fetchAsyncGetMyProf,
    selectProfile,
} from "./postDetailSlice";

import { PROPS_QA_MAPPING } from "../types";


interface PROPS_INPUT {
    postId?: string,
}

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(1),
    },
}));

const PostDetail: React.FC<PROPS_INPUT> = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { postId } = useParams<{postId: string}>();
  const post = useSelector(selectPost);
  const questionsOnPost = useSelector(selectQuestionsOnPost);
  const answers = useSelector(selectAnswers);
  const profileOnPost = useSelector(selectProfileOnPost);
  const profiles = useSelector(selectProfiles);
  const loginProfile = useSelector(selectProfile);
  const loginId = loginProfile.userProfile;
  const [mapping, setMapping] = useState<PROPS_QA_MAPPING>({});

  const apiUrl = process.env.REACT_APP_DEV_API_URL;
  

  const handlerLiked = async() => {
    await dispatch(setIsLoading());
    
    questionsOnPost.forEach(async(question) => {
        const creator_answer = question.creator_answer;
        if (post.liked.includes(loginId)) {
            handleAnswerChange(question.id, 0);
        } else {
            handleAnswerChange(question.id, Number(creator_answer));
        };
    });
    if (postId) {
        await dispatch(fetchAsyncPatchLiked(postId));
    };
    await dispatch(resetIsLoading());
};

    const handleAnswerChange = (questionId: string, newValue: number | null) => {
        const existingAnswer = mapping[questionId];
        let value;
        if (newValue === null) {
            value = 0;
        } else {
            value = newValue;
        }

        if (existingAnswer) {
            const updatedAnswer: PROPS_UPDATEDANSWER = {
                id: existingAnswer.id,
                answerContent: value,
            };
            dispatch(fetchAsyncUpdateAnswer(updatedAnswer));
        } else {
            const newAnswer: PROPS_NEWANSWER = {
                sourceQuestion: questionId,
                answerContent: value,
                is_public: true,
            };
            dispatch(fetchAsyncNewAnswer(newAnswer));
        }
    };

    useEffect(() => {
        const updatedMapping: PROPS_QA_MAPPING = {};
        questionsOnPost.forEach((question) => {
            const correspondingAnswer = answers.find(answer => answer.sourceQuestion === question.id);
            if (correspondingAnswer) {
                updatedMapping[question.id] = correspondingAnswer;
            }
        });
        setMapping(updatedMapping);
    }, [answers]);

    useEffect(() => {
        const initialize = async () => {
          if (postId) {
            try {
              await dispatch(fetchAsyncGetPostDetail(postId)).unwrap();
            } catch (error) {
              navigate('/');
            }
          }
          dispatch(fetchAsyncGetMyProf());
        };
        initialize();
  }, []);
  
  const fetchDataFromPost = async() => {
    await dispatch(fetchAsyncGetProfileUser(post.userPost));
    await dispatch(fetchAsyncGetProfsForPosts([post.id]));
    await dispatch(fetchAsyncSearchQuestions([post.id]));
  };

  useEffect(() => {
    if (post.id !== '') {
        fetchDataFromPost();
    }
  }, [post]);

  useEffect(() => {
    const questionIds = questionsOnPost.map(question => question.id);
    const fetchAnswers = async() => {
            dispatch(fetchAsyncSearchAnswers(questionIds));
    };
    if (questionIds[0] !== ""){
        fetchAnswers();
    }
  }, [questionsOnPost]);

  return (
    <div className={styles.post}>
        <div className={styles.post_header}>
            <Avatar className={styles.post_avatar} src={`${profileOnPost.img}`} />
            <Link to={`/profile/${profileOnPost?.userProfile}`} className={styles.customLink}>
                <h3>{profileOnPost?.nickName}</h3>
            </Link>
        </div>

        <div className={styles.post_text}>{post.text}</div>
        
        {post.img && <img className={styles.post_image} src={`${post.img}`} alt="" />}
        {post.created_on}
        <h4 className={styles.post_text}>
            <Checkbox
                className={styles.post_checkbox}
                icon={<FavoriteBorder />}
                checkedIcon={<Favorite />}
                checked={post.liked.some((like) => like === loginId)}
                onChange={handlerLiked}
                style ={{color: "red",}}
            />
            <AvatarGroup max={7}>
                {post.liked.map((like) => (
                    <Avatar
                        className={styles.post_avatarGroup}
                        key={like}
                        src={`${profiles.find((prof) => prof.userProfile === like)?.img}`}
                    />
                ))}
            </AvatarGroup>
        </h4>

        <Divider />
        <div className={styles.post_comments}>
            {questionsOnPost.map((question) => (
                <div key={question.id} className={styles.post_comment}>
                    <Avatar
                        src={`${profileOnPost.img}`}
                        className={classes.small}
                    />
                    <p>
                        {question.text} {question.creator_answer}
                    </p>
                    <Rating
                        value={mapping[question.id] ? (
                                Number(mapping[question.id].answerContent)
                            ): (
                                0
                            )}
                        onChange={(event, newValue) => handleAnswerChange(question.id, newValue)}
                    />
                </div>
            ))}
        </div>
    </div>
  )
}

export default PostDetail
