import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";
import { PROPS_NEWPOST, PROPS_NEWQUESTION, PROPS_NEWANSWER, PROPS_UPDATEDANSWER } from "../types";

const apiUrl = process.env.REACT_APP_DEV_API_URL;
const apiUrlProfile = `${process.env.REACT_APP_DEV_API_URL}api/profiles-for-posts/`;
const apiUrlPost = `${process.env.REACT_APP_DEV_API_URL}api/post/`;
const apiUrlPostLimited = `${process.env.REACT_APP_DEV_API_URL}api/postlimited/`;
const apiUrlQuestion = `${process.env.REACT_APP_DEV_API_URL}api/question/`;
const apiUrlQuestionSearch = `${process.env.REACT_APP_DEV_API_URL}api/question-search/`;
const apiUrlAnswer = `${process.env.REACT_APP_DEV_API_URL}api/answer/`;
const apiUrlAnswerSearch = `${process.env.REACT_APP_DEV_API_URL}api/answer-search/`;
const apiUrlPatchLiked = `${process.env.REACT_APP_DEV_API_URL}api/patch-liked/`;


export const fetchAsyncGetPosts = createAsyncThunk(
    "core/post/get",
    async () => {
        const res = await axios.get(`${apiUrlPostLimited}?limit=100`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncSearchQuestions = createAsyncThunk(
    "core/question-search",
    async (postIds: string[]) => {
        const data = {
            postIds: postIds
        };
        const res = await axios.post(`${apiUrlQuestionSearch}`, data, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
                // 'Content-Type': 'application/json' はaxiosがデフォルトで設定するため、通常は指定不要
            },
        });
        return res.data;
    }
);

export const fetchAsyncSearchAnswers = createAsyncThunk(
    "core/answer-search",
    async(questionIds: string[]) => {
        const data = {
            questionIds: questionIds
        };
        const res = await axios.post(`${apiUrlAnswerSearch}`, data, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncGetProfsForPosts = createAsyncThunk(
    "core/profiles-for-posts",
    async (postIds: string[]) => {
        const data = {
            postIds: postIds
        };
        const res = await axios.post(`${apiUrlProfile}`, data, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncNewPost = createAsyncThunk(
    "core/post/post",
    async (newPost: PROPS_NEWPOST) => {
        const uploadData = new FormData();
        uploadData.append("text", newPost.text);
        if (newPost.img) {
            uploadData.append("img", newPost.img, newPost.img.name);
        }
        uploadData.append("is_public", newPost.is_public.toString());
        const res = await axios.post(apiUrlPost, uploadData, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncDeletePost = createAsyncThunk(
    "core/post/delete",
    async(postId: string) => {
        const res = await axios.delete(`${apiUrlPost}${postId}/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncNewQuestion = createAsyncThunk(
    "core/question/post",
    async (newQuestion: PROPS_NEWQUESTION) => {
        const uploadData = new FormData();
        uploadData.append("text", newQuestion.text);
        uploadData.append("sourcePost", newQuestion.sourcePost);
        uploadData.append("creator_answer", newQuestion.creator_answer.toString());
        uploadData.append("is_public", newQuestion.is_public.toString());

        const res = await axios.post(apiUrlQuestion, uploadData, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncNewAnswer = createAsyncThunk(
    "core/answer/post",
    async (newAnswer: PROPS_NEWANSWER) => {
        const uploadData = new FormData();
        uploadData.append("answerContent", newAnswer.answerContent.toString());
        uploadData.append("sourceQuestion", newAnswer.sourceQuestion);
        uploadData.append("is_public", newAnswer.is_public.toString());

        const res = await axios.post(apiUrlAnswer, uploadData, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncUpdateAnswer = createAsyncThunk(
    "core/answer/update",
    async (updatedAnswer: PROPS_UPDATEDANSWER) => {
        const uploadData = new FormData();
        uploadData.append("answerContent", updatedAnswer.answerContent.toString());

        const res = await axios.patch(`${apiUrlAnswer}${updatedAnswer.id}/`, uploadData, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncPatchLiked = createAsyncThunk(
    "core/post/patch",
    async (postId: string) => {
        const res = await axios.patch(`${apiUrlPatchLiked}${postId}/`, {}, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);


export const coreSlice = createSlice({
    name:"core",
    initialState: {
        openTimeLine: 1,

        posts: [
            {
                id: "",
                userPost: "",
                text: "",
                img: "",
                liked: [""],
                created_on: "",
                is_public: true,
            },
        ],
        questions: [
            {
                id: "",
                sourcePost: "",
                userQuestion: "",
                text: "",
                creator_answer: "",
                created_on: "",
                is_public: true,
            },
        ],
        answers: [
            {
                id: "",
                sourceQuestion: "",
                userAnswer: "",
                answerContent: "",
                created_on: "",
                is_public: true,
            },
        ],
        profiles: [
            {
                id: "",
                nickName: "",
                userProfile: "",
                created_on: "",
                img: "",
            },
        ],
    },
    reducers: {
        setOpenTimeLine(state) {
            const current = state.openTimeLine;
            state.openTimeLine = current * (-1);
        },

    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncGetPosts.fulfilled, (state, action) => {
            return {
                ...state,
                posts: action.payload,
            };
        });
        builder.addCase(fetchAsyncPatchLiked.fulfilled, (state, action) => {
            const index = state.posts.findIndex((post) => post.id == action.payload.id);
            if (index !== -1) {
                state.posts[index] = action.payload;
            }
        });
        builder.addCase(fetchAsyncNewPost.fulfilled, (state, action) => {
            return {
                ...state,
                posts: [...state.posts, action.payload],
            };
        });
        builder.addCase(fetchAsyncDeletePost.fulfilled, (state, action) => {
            const postId = action.payload.id;
            state.posts = state.posts.filter(post => post.id !== postId);
        });
        builder.addCase(fetchAsyncNewQuestion.fulfilled, (state, action) => {
            return {
                ...state,
                questions: [...state.questions, action.payload],
            };
        });
        builder.addCase(fetchAsyncNewAnswer.fulfilled, (state, action) => {
            return {
                ...state,
                answers: [...state.answers, action.payload],
            };
        });
        builder.addCase(fetchAsyncUpdateAnswer.fulfilled, (state, action) => {
            const index = state.answers.findIndex(answer => answer.id === action.payload.id);
            state.answers[index] = action.payload;
        });
        builder.addCase(fetchAsyncSearchQuestions.fulfilled, (state, action) => {
            state.questions = action.payload;
        });
        builder.addCase(fetchAsyncSearchAnswers.fulfilled, (state, action) => {
            state.answers = action.payload;
        });
        builder.addCase(fetchAsyncGetProfsForPosts.fulfilled, (state, action) => {
            state.profiles = action.payload;
        });
    },
});

export const {
    setOpenTimeLine,

} = coreSlice.actions;

export const selectOpenTimeLine = (state: RootState) => state.core.openTimeLine;

export const selectPosts = (state: RootState) => state.core.posts;
export const selectQuestions = (state: RootState) => state.core.questions;
export const selectAnswers = (state: RootState) => state.core.answers;
export const selectProfiles = (state: RootState) => state.core.profiles;


export default coreSlice.reducer;