import React, {useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { AppDispatch } from '../../app/store';

import { Button, TextField, IconButton, Grid, CircularProgress } from "@mui/material";

const apiUrl = process.env.REACT_APP_DEV_API_URL;

const ResetPassword = () => {
    const { uid, token } = useParams();
    const [tempNewPassword, setTempNewPassword] = useState("");
    const [tempReNewPassword, setTempReNewPassword] = useState("");
    const [state, setState] = useState(0); // 0: 初期状態, 1: 処理中, 2: 成功, 3: エラー
    const [errorMessage, setErrorMessage] = useState(""); // エラーメッセージを保持するステート
  
    const sendNewPassword = async () => {
      setState(1); // 処理中に状態を更新
      const packet = {
        new_password: tempNewPassword,
        re_new_password: tempReNewPassword,
        uid: uid,
        token: token
      };
      try {
        const res = await axios.post(`${apiUrl}api/auth/users/reset_password_confirm/`, packet, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        setState(2); // 成功状態に更新
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          // エラーレスポンスからメッセージを抽出してステートにセット
          const errorData = error.response.data;
          const errorMessages = errorData && typeof errorData === 'object' ? Object.values(errorData).flat().join('\n') : 'An unexpected error occurred';
          setErrorMessage(errorMessages);
          setState(3); // エラー状態に更新
        } else {
          // 予期しないエラー
          setErrorMessage('An unexpected error occurred');
          setState(3); // エラー状態に更新
        }
      }
    }
  
    return (
      <div>
          <h1>Reset Password</h1>
          <br/>
  
          {state === 0 && (
              <>
                  New password:
                  <TextField
                      type="password"
                      value={tempNewPassword}
                      onChange={(e) => setTempNewPassword(e.target.value)}
                  />
                  <br/>
  
                  Retype new password:
                  <TextField
                      type="password"
                      value={tempReNewPassword}
                      onChange={(e) => setTempReNewPassword(e.target.value)}
                  />
                  <br/>
  
                  <Button
                      disabled={!tempNewPassword || !tempReNewPassword}
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={sendNewPassword}
                  >
                      Change Password
                  </Button>
              </>
          )}
  
          {state === 1 && <CircularProgress/>}
          {state === 2 && (
              <>
                  Password changed!
                  Your password has been changed successfully.
                  <br/>
                  <Link to="/">Go to mainpage</Link>
              </>
          )}
          {state === 3 && (
              <>
                  Error: {errorMessage}
                  <br/>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setState(0)} // エラーメッセージをリセットして再試行を許可
                  >
                      Retry
                  </Button>
              </>
          )}
      </div>
    )
  }
  
  export default ResetPassword;
  
// import React, {useState} from 'react';
// import { useParams, Link } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import axios from 'axios';
// import { AppDispatch } from '../../app/store';

// import { Button, TextField, IconButton, Grid, CircularProgress } from "@mui/material";

// const apiUrl = process.env.REACT_APP_DEV_API_URL;

// const ResetPassword = () => {
//   const { uid, token } = useParams();
//   const [tempNewPassword, setTempNewPassword] = useState("");
//   const [tempReNewPassword, setTempReNewPassword] = useState("");
//   const [state, setState] = useState(0);

//   const sendNewPassword = async () => {
//     setState(1);
//     const packet = {
//         new_password: tempNewPassword,
//         re_new_password: tempReNewPassword,
//         uid: uid,
//         token: token
//     }
//     const res = await axios.post(`${apiUrl}api/auth/users/reset_password_confirm/`, packet, {
//         headers: {
//             "Content-Type": "application/json",
//         },
//     });
//     setState(2);
//   }

//   return (
//     <div>
//         <h1>Reset Password</h1>
//         <br/>

//         {state===0 && (
//             <>
//         New password:
//         <TextField
//             type="password"
//             value={tempNewPassword}
//             onChange={(e) => setTempNewPassword(e.target.value)}
//         />
//         <br/>

//         Retype new password:
//         <TextField
//             type="password"
//             value={tempReNewPassword}
//             onChange={(e) => setTempReNewPassword(e.target.value)}
//         />
//         <br/>

//         <Button
//             disabled={!tempNewPassword || !tempReNewPassword}
//             variant="contained"
//             color="primary"
//             type="submit"
//             onClick={sendNewPassword}
//         >
//             Change Password
//         </Button>
//             </>
//         )}

//         {(state===1) && <CircularProgress/>}
//         {(state===2) && (
//             <>
//                 Password changed!
//                 Your password has been changed successfully.
//                 <br/>
//                 <Link to="/">Go to mainpage</Link>
//             </>
//         )}
//     </div>
//   )
// }

// export default ResetPassword
