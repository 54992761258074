import React, { useState, useEffect } from "react";
import styles from "./Post.module.css";
import { Link } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { Rating, Avatar, Divider, Checkbox, Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';

import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";

import {
    fetchPostStart,
    fetchPostEnd,
} from "./postSlice";

import {
    fetchAsyncGetPosts,
    fetchAsyncPatchLiked,
    fetchAsyncNewAnswer,
    fetchAsyncUpdateAnswer,
    fetchAsyncDeletePost,
    selectPosts,
    selectQuestions,
    selectAnswers,
    selectProfiles,
} from "../core/coreSlice";

import { PROPS_POST, PROPS_QA_MAPPING, PROPS_NEWANSWER, PROPS_UPDATEDANSWER } from "../types";

const apiUrl = process.env.REACT_APP_DEV_API_URL;

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(1),
    },
}));

const Post: React.FC<PROPS_POST> = ({
    postId,
    loginId,
    userPost,
    text,
    imageUrl,
    liked,
    is_public,
}) => {
    const classes = useStyles();
    const dispatch: AppDispatch = useDispatch();
    const profiles = useSelector(selectProfiles);
    const questions = useSelector(selectQuestions);
    const answers = useSelector(selectAnswers);
    const [mapping, setMapping] = useState<PROPS_QA_MAPPING>({});

    const [isExpanded, setIsExpanded] = useState(false);
    const displayText = isExpanded || text.length <= 140 ? text : `${text.substring(0, 140)}...`;


    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    const questionsOnPost = questions.filter((q) => {
        return q.sourcePost == postId;
    });

    const prof = profiles.filter((prof) => {
        return prof.userProfile === userPost;
    });

    const [openDialog, setOpenDialog] = useState(false);


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleCloseMenu();
    };
    const handleDeletePost = async () => {
        await dispatch(fetchAsyncDeletePost(postId));
        handleCloseDialog(); // 削除後、Dialogを閉じる
    };

    const handlerLiked = async() => {
        await dispatch(fetchPostStart());
        
        questionsOnPost.forEach(async(question) => {
            const creator_answer = question.creator_answer;
            if (liked.includes(loginId)) {
                handleAnswerChange(question.id, 0);
            } else {
                handleAnswerChange(question.id, Number(creator_answer));
            };
        });
        await dispatch(fetchAsyncPatchLiked(postId));

        await dispatch(fetchAsyncGetPosts());
        await dispatch(fetchPostEnd());
    };

    const handleAnswerChange = (questionId: string, newValue: number | null) => {
        const existingAnswer = mapping[questionId];
        let value;
        if (newValue === null) {
            value = 0;
        } else {
            value = newValue;
        }

        if (existingAnswer) {
            const updatedAnswer: PROPS_UPDATEDANSWER = {
                id: existingAnswer.id,
                answerContent: value,
            };
            dispatch(fetchAsyncUpdateAnswer(updatedAnswer));
        } else {
            const newAnswer: PROPS_NEWANSWER = {
                sourceQuestion: questionId,
                answerContent: value,
                is_public: true,
            };
            dispatch(fetchAsyncNewAnswer(newAnswer));
        }
    };

    useEffect(() => {
        const updatedMapping: PROPS_QA_MAPPING = {};
        questions.forEach((question) => {
            const correspondingAnswer = answers.find(answer => answer.sourceQuestion === question.id);
            if (correspondingAnswer) {
                updatedMapping[question.id] = correspondingAnswer;
            }
        });
        setMapping(updatedMapping);
    }, [answers]);

    return (
        <div className={styles.post}>
            <div className={styles.post_header}>
                <Avatar className={styles.post_avatar} src={`${prof[0]?.img}`} />
                <Link to={`/profile/${prof[0]?.userProfile}`} className={styles.customLink}>
                    <h3>{prof[0]?.nickName}</h3>
                </Link>
            
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClickMenu}
                    style={{ marginLeft: 'auto' }} // ボタンを右に配置
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={openMenu}
                    onClose={handleCloseMenu}
                >
                    {loginId === userPost && <MenuItem onClick={handleOpenDialog} style={{color:'red'}}>Delete</MenuItem>}
                    {/* <MenuItem onClick={handleCloseMenu}>Share(Coming soon)</MenuItem> */}
                    <MenuItem component={Link} to={`/post-detail/${postId}/`}>
                        Detail
                    </MenuItem>
                </Menu>

                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are you sure you want to delete this post?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleDeletePost} autoFocus　style={{ color: 'red' }}>
                            Delete
                        </Button>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div className={styles.post_text}>
                {displayText}
                {!isExpanded && text.length > 140 && (
                    <Button onClick={() => setIsExpanded(true)} style={{ color: 'blue', marginTop: '5px' }}>
                    Read more
                </Button>
                )}
            </div>

            {imageUrl && <img className={styles.post_image} src={`${imageUrl}`} alt="" />}

            <h4 className={styles.post_text}>
                <Checkbox
                    className={styles.post_checkbox}
                    icon={<FavoriteBorder />}
                    checkedIcon={<Favorite />}
                    checked={liked.some((like) => like === loginId)}
                    onChange={handlerLiked}
                    style ={{color: "red",}}
                />
                <AvatarGroup max={7}>
                    {liked.map((like) => (
                        <Avatar
                            className={styles.post_avatarGroup}
                            key={like}
                            src={`${profiles.find((prof) => prof.userProfile === like)?.img}`}
                        />
                    ))}
                </AvatarGroup>
            </h4>

            {questionsOnPost.length > 0 && (
                <>
                    <Divider />
                    <div className={styles.post_comments}>
                        {questionsOnPost.map((question) => (
                            <div key={question.id} className={styles.post_comment}>
                                <Avatar
                                    src={`${profiles.find((prof) => prof.userProfile === question.userQuestion)?.img}`}
                                    className={classes.small}
                                />
                                <p>
                                    {question.text} {question.creator_answer}
                                </p>
                                <Rating
                                    value={mapping[question.id] ? (
                                            Number(mapping[question.id].answerContent)
                                        ): (
                                            0
                                        )}
                                    onChange={(event, newValue) => handleAnswerChange(question.id, newValue)}
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}

            
        </div>
    )
};

export default Post;
