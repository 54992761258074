import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import axios from "axios";

export const postSlice = createSlice({
    name: "post",
    initialState: {
        isLoadingPost: false,
    },
    reducers: {
        fetchPostStart(state) {
            state.isLoadingPost = true;
        },
        fetchPostEnd(state) {
            state.isLoadingPost = false;
        },

    },
});

export const {
    fetchPostStart,
    fetchPostEnd,

} = postSlice.actions;

export const selectIsLoadingPost = (state: RootState) => state.post.isLoadingPost;


export default postSlice.reducer;