import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import axios from "axios";

const apiUrlAnswerUser = `${process.env.REACT_APP_DEV_API_URL}api/answer-user/`;
const apiUrlQuestionFromAnswer = `${process.env.REACT_APP_DEV_API_URL}api/question-from-answer/`;
const apiUrlAnswer = `${process.env.REACT_APP_DEV_API_URL}api/answer/`;

export const fetchAsyncGetAnswerUser = createAsyncThunk(
    "answerProfile/answer-user/get",
    async(userId: string) => {
        const res = await axios.get(`${apiUrlAnswerUser}${userId}/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncGetQuestionFromAnswer = createAsyncThunk(
    "answerProfile/question-from-answer/get",
    async(answerIds: string[]) => {
        const res = await axios.post(`${apiUrlQuestionFromAnswer}`, {answerIds: answerIds}, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
)

export const fetchAsyncDeleteAnswer = createAsyncThunk(
    "answerProfile/answer/delete",
    async(answerId: string) => {
        const res = await axios.delete(`${apiUrlAnswer}${answerId}/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const answerProfileSlice = createSlice({
    name:"answerProfile",
    initialState: {
        answers: [
            {
                id: "",
                sourceQuestion: "",
                userAnswer: "",
                answerContent: "",
                created_on: "",
                is_public: true,
            },
        ],
        questions: [
            {
                id: "",
                sourcePost: "",
                userQuestion: "",
                text: "",
                creator_answer: "",
                created_on: "",
                is_public: true,
            },
        ],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncGetAnswerUser.fulfilled, (state, action) => {
            state.answers = action.payload;
        });
        builder.addCase(fetchAsyncGetQuestionFromAnswer.fulfilled, (state, action) => {
            state.questions = action.payload;
        });
        builder.addCase(fetchAsyncDeleteAnswer.fulfilled, (state, action) => {
            const answerId = action.payload.id;
            state.answers = state.answers.filter(answer => answer.id !== answerId);
        });
    },
});

export const selectAnswers = (state: RootState) => state.answerProfile.answers;
export const selectQuestions = (state: RootState) => state.answerProfile.questions;

export default answerProfileSlice.reducer;
