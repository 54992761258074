import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import axios from "axios";
import { PROPS_NEWANSWER, PROPS_UPDATEDANSWER } from "../../types";

const apiUrlPostUser = `${process.env.REACT_APP_DEV_API_URL}api/post-user/`;
const apiUrlQuestionSearch = `${process.env.REACT_APP_DEV_API_URL}api/question-search/`;
const apiUrlAnswerSearch = `${process.env.REACT_APP_DEV_API_URL}api/answer-search/`;
const apiUrlProfile = `${process.env.REACT_APP_DEV_API_URL}api/profiles-for-posts/`;
const apiUrlAnswer = `${process.env.REACT_APP_DEV_API_URL}api/answer/`;
const apiUrlPost = `${process.env.REACT_APP_DEV_API_URL}api/post/`;
const apiUrlPatchLiked = `${process.env.REACT_APP_DEV_API_URL}api/patch-liked/`;


export const fetchAsyncGetPostsUser = createAsyncThunk(
    "postProfile/post-user/get",
    async(userId: string) => {
        const res = await axios.get(`${apiUrlPostUser}${userId}/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncDeletePost = createAsyncThunk(
    "core/post/delete",
    async(postId: string) => {
        const res = await axios.delete(`${apiUrlPost}${postId}/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncSearchQuestions = createAsyncThunk(
    "postProfile/question-search",
    async (postIds: string[]) => {
        const data = {
            postIds: postIds
        };
        const res = await axios.post(`${apiUrlQuestionSearch}`, data, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncSearchAnswers = createAsyncThunk(
    "postProfile/answer-search",
    async(questionIds: string[]) => {
        const data = {
            questionIds: questionIds
        };
        const res = await axios.post(`${apiUrlAnswerSearch}`, data, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncGetProfsForPosts = createAsyncThunk(
    "postProfile/profiles-for-posts",
    async (postIds: string[]) => {
        const data = {
            postIds: postIds
        };
        const res = await axios.post(`${apiUrlProfile}`, data, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncNewAnswer = createAsyncThunk(
    "postProfile/answer/post",
    async (newAnswer: PROPS_NEWANSWER) => {
        const uploadData = new FormData();
        uploadData.append("answerContent", newAnswer.answerContent.toString());
        uploadData.append("sourceQuestion", newAnswer.sourceQuestion);

        const res = await axios.post(apiUrlAnswer, uploadData, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncUpdateAnswer = createAsyncThunk(
    "postProfile/answer/update",
    async (updatedAnswer: PROPS_UPDATEDANSWER) => {
        const uploadData = new FormData();
        uploadData.append("answerContent", updatedAnswer.answerContent.toString());

        const res = await axios.patch(`${apiUrlAnswer}${updatedAnswer.id}/`, uploadData, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const fetchAsyncPatchLiked = createAsyncThunk(
    "postProfile/post/patch",
    async (postId: string) => {
        const res = await axios.patch(`${apiUrlPatchLiked}${postId}/`, {}, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);


export const postProfileSlice = createSlice({
    name:"postProfile",
    initialState: {
        profiles: [
            {
                id: "",
                nickName: "",
                userProfile: "",
                created_on: "",
                img: "",
            },
        ],
        posts: [
            {
                id: "",
                userPost: "",
                text: "",
                img: "",
                liked: [""],
                created_on: "",
                is_public: true,
            },
        ],
        questions: [
            {
                id: "",
                sourcePost: "",
                userQuestion: "",
                text: "",
                creator_answer: "",
                created_on: "",
                is_public: true,
            },
        ],
        answers: [
            {
                id: "",
                sourceQuestion: "",
                userAnswer: "",
                answerContent: "",
                created_on: "",
                is_public: true,
            },
        ],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        // builder.addCase(fetchAsyncGetProfileUser.fulfilled, (state, action) => {
        //     state.userProfile = action.payload;
        // });
        builder.addCase(fetchAsyncGetPostsUser.fulfilled, (state, action) => {
            state.posts = action.payload;
        });
        builder.addCase(fetchAsyncDeletePost.fulfilled, (state, action) => {
            const postId = action.payload.id;
            state.posts = state.posts.filter(post => post.id !== postId);
        });
        builder.addCase(fetchAsyncSearchQuestions.fulfilled, (state, action) => {
            state.questions = action.payload;
        });
        builder.addCase(fetchAsyncSearchAnswers.fulfilled, (state, action) => {
            state.answers = action.payload;
        });
        builder.addCase(fetchAsyncGetProfsForPosts.fulfilled, (state, action) => {
            state.profiles = action.payload;
        });
        builder.addCase(fetchAsyncNewAnswer.fulfilled, (state, action) => {
            return {
                ...state,
                answers: [...state.answers, action.payload],
            }
        });
        builder.addCase(fetchAsyncUpdateAnswer.fulfilled, (state, action) => {
            const index = state.answers.findIndex(answer => answer.id === action.payload.id);
            state.answers[index] = action.payload;
        });
        builder.addCase(fetchAsyncPatchLiked.fulfilled, (state, action) => {
            const index = state.posts.findIndex((post) => post.id == action.payload.id);
            if (index !== -1) {
                state.posts[index] = action.payload;
            }
        });
    }
});

// export const selectUserProfile = (state: RootState) => state.postProfile.userProfile;
export const selectProfiles = (state: RootState) => state.postProfile.profiles;
export const selectPosts = (state: RootState) => state.postProfile.posts;
export const selectQuestions = (state: RootState) => state.postProfile.questions;
export const selectAnswers = (state: RootState) => state.postProfile.answers;

export default postProfileSlice.reducer;
