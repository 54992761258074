import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { AppDispatch } from '../../app/store';
import { Link } from 'react-router-dom';

import { TextField, Button, CircularProgress } from "@mui/material";

import {
    fetchAsyncPasswordReset,
} from "./authSlice";

const SendResetPasswordEmail = () => {
  const dispatch: AppDispatch = useDispatch();
  const [tempText, setTempText] = useState("");
  const [state, setState] = useState(0);

  const handleResetPassword = async() => {
    setState(1);
    const res = await dispatch(fetchAsyncPasswordReset(tempText));
    setState(2);
    
  }

  return (
    <div>
        <h1>Reset password</h1>

        <br/>
        Email:
        <TextField
            value={tempText}
            type="text"
            onChange={(e) => setTempText(e.target.value)}
        />
        <br/>

        <Button
            onClick={handleResetPassword}
            variant="contained"
            color="primary"
        >
            Send
        </Button>
        <br/>

        {state===1 && <CircularProgress/>}
        {state===2 && (
            <>
                <br/><br/>
                A password reset link has been sent to your email.
                <br/>
                If you do not receive the email, please resubmit the form. 
                <br/>
                If you do not have an account, please register first from the <Link to="/?modal=signup">signup page</Link>.
            </>
        )}

    </div>
  )
}

export default SendResetPasswordEmail
