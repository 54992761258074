import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';

import { Grid, Checkbox, Divider, Rating, Avatar, AvatarGroup } from "@mui/material";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import styles from "./ProfProfile.module.css";

import {
  selectUserProfile,
  fetchAsyncGetProfileUser,
} from "./profProfileSlice";

interface PROPS_INPUT {
  userId?: string,
}

const ProfProfile: React.FC<PROPS_INPUT> = ({userId}) => {
  const dispatch: AppDispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  const apiUrl = process.env.REACT_APP_DEV_API_URL;

  useEffect(() => {
    const fetchUserProfile = async() => {
      if (userId) {
        dispatch(fetchAsyncGetProfileUser(userId));
      };
    };
    fetchUserProfile();
  }, []);

  return (
    <div>
      <h1 className={styles.profile_nickname}>{userProfile.nickName}</h1>
      
      <Avatar src={`${userProfile.img}`} />
      <br/>

      <div className={styles.profile_text}>
      {userProfile.text}
      <br/>

      X: {userProfile.twitterId}
      <br/>

      Today {Number(userProfile.point).toFixed(1)}/{Number(userProfile.point_limit).toFixed(1)} (reset at 4:00a.m.)
      <br/>
      </div>

    </div>
  )
}

export default ProfProfile
