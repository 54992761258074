import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom";
import ProfProfile from './profProfile/ProfProfile';
import PostProfile from './postProfile/PostProfile';
import AnswerProfile from './answerProfile/AnswerProfile';
import { AppDispatch } from '../../app/store';

import { Box, Tabs, Tab, useRadioGroup } from "@mui/material";


const Profile = () => {
  const dispatch: AppDispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const { userId } = useParams<{userId: string}>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: '100%', bgcolor: 'background.paper'}}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Profile" />
          <Tab label="Posts" />
          <Tab label="Answers" />
        </Tabs>
        {value === 0 && <ProfProfile userId={userId}/>}
        {value === 1 && <PostProfile userId={userId}/>}
        {value === 2 && <AnswerProfile userId={userId}/>}
      </Box>
    </div>
  );
}

export default Profile