import React, {useEffect, useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { AppDispatch } from '../../app/store';

import { CircularProgress } from '@mui/material';

const apiUrl = process.env.REACT_APP_DEV_API_URL;

const RegisterComplete = () => {
  const { uid, token } = useParams();
  const [message, setMessage] = useState("Activating...");
  const [activationState, setActivationState] = useState(0);

  const sendActivate = async () => {
    const packet = {
      uid: uid,
      token: token
    }
    const res = await axios.post(`${apiUrl}api/auth/users/activation/`, packet, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    setMessage("Completed!");
    setActivationState(1);
  };

  useEffect(() => {
    sendActivate();
  }, []);

  return (
    <div>
      <h2>{message}</h2>
      {activationState===0 && <CircularProgress/>}
      {(activationState===1) && <Link to="/">Go to mainpage</Link>}
    </div>
  )
}

export default RegisterComplete