import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import axios from "axios";

const apiUrlProfileUser = `${process.env.REACT_APP_DEV_API_URL}api/profile-user/`;

export const fetchAsyncGetProfileUser = createAsyncThunk(
    "profProfile/profile-user/get",
    async (userId: string) => {
        const res = await axios.get(`${apiUrlProfileUser}${userId}/`, {
            headers: {
                Authorization: `JWT ${localStorage.localJWT}`,
            },
        });
        return res.data;
    }
);

export const profProfileSlice = createSlice({
    name:"profProfile",
    initialState: {
        userProfile: {
            id: "",
            nickName: "",
            userProfile: "",
            created_on: "",
            img: "",
            text: "",
            twitterId: "",
            point: "",
            point_limit: "",
        },
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncGetProfileUser.fulfilled, (state, action) => {
            state.userProfile = action.payload;
        });
    }
})

export const selectUserProfile = (state: RootState) => state.profProfile.userProfile;

export default profProfileSlice.reducer;