import React from 'react';
import styles from './App.module.css';
import Core from "./features/core/Core";
import Profile from "./features/profile/Profile";
import PostDetail from "./features/postDetail/PostDetail";
import RegisterComplete from "./features/auth/RegisterComplete";
import ResetPassword from './features/auth/ResetPassword';
import SendResetPasswordEmail from "./features/auth/SendResetPasswordEmail";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import TestPage from "./features/testPage/TestPage";

function App() {
  return (
    <Router>
      <div className={styles.app}>
        <Routes>
          <Route path="/" element={<Core />} />
          <Route path="/profile/:userId" element={<Profile />} />
          <Route path="/post-detail/:postId" element={<PostDetail />} />
          <Route path="/activate/:uid/:token" element={<RegisterComplete />} />
          <Route path="/password/reset/confirm/:uid/:token" element={<ResetPassword />} />
          <Route path="/password-reset-form" element={<SendResetPasswordEmail />} />

          <Route path="/testpage" element={<TestPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
