import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Modal from "react-modal";
import { AppDispatch } from "../../app/store";
import styles from "./Match.module.css";

import { Button, CircularProgress, Avatar } from "@material-ui/core";

// const apiUrl = process.env.REACT_APP_DEV_API_URL;

import {
    selectIsLoadingMatch,
    setIsLoadingMatch,
    resetIsLoadingMatch,
    selectIsOpenMatch,
    setIsOpenMatch,
    selectMatchResult,
    resetIsOpenMatch,
    fetchAsyncMatch,
    selectProfiles,
    fetchAsyncGetProfFromIds,
} from "./matchSlice";

import { 
    selectProfile
} from "../auth/authSlice";


const customStyles = {
    content: {
      top: "55%",
      left: "50%",
  
      width: "50%",
      height: "auto",
      padding: "50px",
  
      transform: "translate(-50%, -50%)",
    },
  };


const Match: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const isOpenMatch = useSelector(selectIsOpenMatch);
    const isLoadingMatch = useSelector(selectIsLoadingMatch);
    const matchResult = useSelector(selectMatchResult);
    const profiles = useSelector(selectProfiles);
    const myprofile = useSelector(selectProfile);
    const apiUrl = process.env.REACT_APP_DEV_API_URL;
    
    const renderMatchResult = () => {
        return matchResult.map((match, index) => {
            if (match.userId === "") {
                return null;
            }
            const profile = profiles.find(profile => profile.userProfile === match.userId);
            return (
                <div key={index}>
                    <Avatar className={styles.post_avatar} src={`${profile?.img}`} />
                    <a href={`${window.location.origin}/profile/${match.userId}/`} target="_blank" rel="noopener noreferrer">
                        {profile?.nickName}
                    </a>
                    {`  Score:${(Number(match.score)*10).toFixed(1)}`}
                    <br/><br/>
                </div>
            );
        });
    };

    const getMatchResult = async() => {
        dispatch(fetchAsyncMatch());
    };

    useEffect(() => {
        const userIds = matchResult.map(item => item.userId);
        const fetchProfiles = async() => {
            await dispatch(fetchAsyncGetProfFromIds(userIds));
        };
        fetchProfiles();
    }, [matchResult]);

    return (
        <>
            <Modal
                isOpen={isOpenMatch}
                onRequestClose={async () => {
                    await dispatch(resetIsOpenMatch());
                }}
                style={customStyles}
            >
            <h1 className={styles.core_title}>Search</h1>
            
            {isLoadingMatch && <CircularProgress />}
            
            {renderMatchResult()}
            <br/>
            
            My profile: <a href={`${window.location.origin}/profile/${myprofile.userProfile}/`} target="_blank" rel="noopener noreferrer">
                {myprofile.nickName}
            </a>
            <br/><br/>

            <Button
                onClick={getMatchResult}
                variant="contained"
                color="primary"
            >
                Search!
            </Button>
            </Modal>
        </>
    );
}

export default Match;
